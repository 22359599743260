import React, { useState, useMemo, useEffect } from 'react';
import InputMask from "react-input-mask";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { sendNotification } from '../services/Functions'
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

//id, coupon, benefit, benefitValue, firstOrder, units, userMaxQuantity, orderMinValue, orderMaxValue, discountMax, startDate, expirationDate, active, onDelete, loadItem, setLoading
const ListItemCoupon = (props) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [coupon, setCoupon] = useState(props.coupon);
  const [benefit, setBenefit] = useState(props.benefit);
  const [benefitValue, setBenefitValue] = useState(props.benefitValue);
  const [orderMinValue, setOrderMinValue] = useState(props.orderMinValue);
  const [orderMaxValue, setOrderMaxValue] = useState(props.orderMaxValue);
  const [discountMax, setDiscountMax] = useState(props.discountMax);
  const [startDate, setStartDate] = useState(new Date(props.startDate.substr(0, 19)));
  const [expirationDate, setExpirationDate] = useState(new Date(props.expirationDate.substr(0, 19)));
  const [units, setUnits] = useState(props.units);
  const [userMaxQuantity, setUserMaxQuantity] = useState(props.userMaxQuantity);
  const [firstOrder, setFirstOrder] = useState(props.firstOrder);
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    setCoupon(formatCoupon(coupon));
  }, [coupon]);

  function formatCoupon(string){
    return string.toUpperCase().replace(/[^A-Z0-9]+/g, "").replace(/^-+|-+$/g, "").replace(/^-+|-+$/g, '');
  };

  function generateCoupom(){
    setCoupon(formatCoupon(Math.random().toString(36).substring(5)));
  };

  function onChangeBenefit(value){
    setBenefit(value);
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(coupon !== "" && benefit !== "" && benefit !== "" && parseInt(units) > 0 && parseInt(userMaxQuantity) > 0
    && parseInt(orderMaxValue) > 0 && parseInt(discountMax) > 0 && startDate !== "" && expirationDate !== ""){
      props.setLoading(true);
      var data = {
        id: props.id+"",
        coupon: coupon, 
        benefit: benefit, 
        benefitValue: benefitValue && benefitValue !== "" ? benefitValue : undefined, 
        units: units, 
        userMaxQuantity: userMaxQuantity, 
        orderMinValue: orderMinValue, 
        orderMaxValue: orderMaxValue, 
        discountMax: discountMax, 
        startDate: startDate, 
        expirationDate: expirationDate, 
        firstOrder: firstOrder, 
        active: active
      }
      try {
        const response = await Api.saveItem("/store-coupon", data);
        console.log(response);
        if(response.hasOwnProperty('_id')){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setToggleItem([]);
          const reloadItem = props.loadItem;
          reloadItem();
          props.setLoading(false);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          props.setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  
  return (
      <li className={toggleItem[0] === props.id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"} style={props.active || active ? {} : {opacity: 0.4}}>
        <div className="row headList" onClick={() => {toggleItem[0] !== props.id ? setToggleItem([props.id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{coupon}</span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${toggleItem[0] === props.id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === props.id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <label className="switch itemDashboardListSwitch" style={{marginTop: -60, marginRight: 45}}>
                      <input type="checkbox" value={active} checked={active} onChange={() => setActive(!active)} />
                      <div className="slider"></div>
                    </label>
                    <div className="row">
                      <div className="form-group col-md-9 col-6">
                        <label className="labelInput">Cupom: *</label>
                        <input 
                          required
                          type="text" 
                          className="form-control" 
                          placeholder="Código do Cupom"
                          value={coupon}
                          onChange={event => setCoupon((event.target.value).toUpperCase())}
                        />
                      </div>   
                      <div className="form-group col-md-3 col-6" style={{marginTop: 7}}>
                        <button type="button" className="btn btn-default" onClick={generateCoupom} style={{width:'100%', marginLeft: -15, fontSize: 12}}> Gerar Cupom </button>   
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12">
                        <label className="labelInput">Benefício: *</label>
                        <select className="form-control" value={benefit} onChange={event => onChangeBenefit(event.target.value)} required>
                          {/*<option key="0" value={benefit}>{benefit}</option>*/}
                          <option key="1">Desconto em R$</option>
                          <option key="2">Desconto em %</option>
                          {/*<option key="3">Frete Grátis</option>*/}
                        </select>
                      </div>  
                    </div>
                    <div className="row">
                      {benefit === "Desconto em R$" || benefit === "Desconto em %" ?
                      <>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">Valor do Benefício: *</label>
                          <input 
                            required
                            type="number" 
                            min="0"
                            step="0.01"
                            className="form-control" 
                            placeholder="Valor do Benefício"
                            value={benefitValue}
                            onChange={event => setBenefitValue(event.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-6 col-12">
                          <label className="labelInput">Valor Máximo de Desconto: *</label>
                          <input 
                            disabled={true}
                            type="number" 
                            min="0"
                            step="0.01"
                            className="form-control" 
                            placeholder="(R$) Valor máximo do desconto"
                            value={benefit === "Desconto em R$" ? benefitValue : (benefitValue/100) * orderMaxValue}
                            onChange={event => setDiscountMax(event.target.value)}
                          />
                        </div>
                      </>
                      : <></> }
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <label className="labelInput">Valor Mínimo do Pedido: *</label>
                        <input 
                          required
                          type="number" 
                          min="0"
                          step="0.01"
                          className="form-control" 
                          placeholder="(R$) Valor mínimo do pedido"
                          value={orderMinValue}
                          onChange={event => setOrderMinValue(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Valor Máximo do Pedido: *</label>
                        <input 
                          required
                          type="number" 
                          min="0"
                          step="0.01"
                          className="form-control" 
                          placeholder="(R$) Valor máximo do pedido"
                          value={orderMaxValue}
                          onChange={event => setOrderMaxValue(event.target.value)}
                        />
                      </div>
                    </div>   
                    <div className="row">
                      <div className="form-group col-6">
                        <label className="labelInput">Data de Início: *</label>
                        <DatePicker 
                          required
                          className="form-control" 
                          selected={startDate} 
                          onChange={(date) => setStartDate(date)} 
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          //locale="pt-BR"
                          dateFormat="dd/MM/yyyy, hh:mm a"
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Data de Expiração: *</label>
                        <DatePicker 
                          required
                          className="form-control" 
                          selected={expirationDate} 
                          onChange={(date) => setExpirationDate(date)} 
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          //locale="pt-BR"
                          dateFormat="dd/MM/yyyy, hh:mm a"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                        <label className="labelInput">Quantidade de Cupons: *</label>
                        <input 
                          required
                          type="number" 
                          min="0"
                          step="1"
                          className="form-control" 
                          placeholder="Quantidade máxima de cupons disponíveis"
                          value={units}
                          onChange={event => setUnits(event.target.value)}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label className="labelInput">Quantidade por Usuário: *</label>
                        <input 
                          required
                          type="number" 
                          min="0"
                          step="1"
                          className="form-control" 
                          placeholder="Quantidade máxima por usuário"
                          value={userMaxQuantity}
                          onChange={event => setUserMaxQuantity(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12">
                        <label>
                          <input 
                            type="checkbox" 
                            style={{marginLeft: 8, marginTop: 5, marginRight: 10, transform: 'scale(1.5)'}}
                            checked={firstOrder}
                            onChange={() => setFirstOrder(!firstOrder)}
                          />
                          Aplicável apenas no primeiro pedido?
                        </label>
                      </div>
                    </div>
                  </div>    
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={props.onDelete}> Excluir </button>
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <button type="submit" className="btn btn-default"> Salvar </button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemCoupon;
