//useEffect = Método chamado ao carragar a página;
import React, {useEffect, useState} from 'react';
//import Functions from '../../services/Functions'
import { toast } from 'react-toastify';
import Api from '../../services/Api'
import './styles.css';

export default function DashboardNotifications({setLoading}) {
  const [StateTitle, setStateTitle] = useState("");
  const [StateBody, setStateBody] = useState("");
  const [stateFilter, setStateFilter] = useState({id: "*", name: "Todos os usuários"});
  const [catequese, setCatequese] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  async function onChangeFilter(idCatequese){
    if(idCatequese !== "*"){
      const responseCatequese = await Api.getCatequese(idCatequese)
      setStateFilter(responseCatequese);
    } else {
      setStateFilter({id: "*", name: "Todos os usuários"});
    }
  }

  async function handleSubmit(e){
    e.preventDefault();
    if(StateTitle !== "" && StateBody !== ""){
      if(window.confirm('Confirme para enviar a notificação!')){
        const response = await Api.sendNotification(StateTitle, StateBody, stateFilter.id, '*');
        //console.log(response);
        if(response.data.hasOwnProperty('menssage') && response.data.menssage === "OK"){
          toast.success("Notificação enviada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setStateTitle("");
          setStateBody("");
          setStateFilter({id: "*", name: "Todos os usuários"});
        } else {
          toast.error("Falha ao enviar a notificação. Tente novamente em breve!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      toast.warning("Preencha todos os campos!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }
  

  return (
    <div className="bodyDashboard">
      <div className="itensHome v-center">
        <h3 className="title">Enviar Notificação</h3>
        <form onSubmit={handleSubmit} id="formNotification">
          <div className="form-group col-12">
            <label className="labelInput">Título:</label>
            <input 
              type="text" 
              className="form-control" 
              name="title"
              placeholder="Título da Notificação"
              value={StateTitle}
              onChange={(event) => setStateTitle(event.target.value)}
              //onChange={event => setEmail(event.target.value)}
              />
          </div>     
          <div className="form-group col-12">
            <label className="labelInput">Mensagem:</label>
            <textarea
              type="text"  
              className="form-control" 
              name="body" 
              rows="5"
              placeholder="Texto da Notificação"
              value={StateBody}
              onChange={(event) => setStateBody(event.target.value)}
            />
          </div>
          <div className="col-12 text-right">
            <button type="submit" className="btn btn-default">Enviar Notificação</button>
          </div>
        </form>
      </div>
    </div>
  )
 
}