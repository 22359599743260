//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemUser from '../../components/ListItemUser'
import './styles.css';
import '../../App.css';

export default function DashboardUsers({setLoading}) {
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);


 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadUsers();
  }, []);

  async function loadUsers() {
    try {
      const response = await Api.getUsers();
      setUsers(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  async function addUser(){
    const userCopy = Array.from(users);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    userCopy.unshift({
      _id: userCopy.length +1,
      name: "",
      email: "",
      premium: "",
      datePayment: "",
      dateValidPremium: "",
      codeConfirm: "",
      accountConfirm: "",
      token: "",
      admin: "",
      active: "",
      //date: new Date().toISOString().substr(0,10),
    });
    await setUsers(userCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(userCopy.length)
  }

  async function removeUser(id){
    setLoading(true);
    const userCopy = Array.from(users);
    var index = userCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-user', {'id': id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          userCopy.splice(index, 1);
          setUsers(userCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      userCopy.splice(index, 1);
      setUsers(userCopy);
    }
    setLoading(false);
  }

  //console.log(users);
  //const childRef = useRef();
  return (
    <div id="userList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Usuários</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          {/*<button type="button" onClick={addUser} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {users.length > 0 ?
            users.map(item => (
              <ListItemUser
                key={item._id}
                id={item._id}
                name={item.name}
                email={item.email}
                telefone={item.telefone}
                cpf={item.cpf}
                dataNascimento={item.dataNascimento} 
                dateCreated= {item.dateCreated}
                catequeseList={item.catequeseList} 
                addresses={item.addresses}
                token={item.hasOwnProperty('token') ? item.token : ''}
                admin={item.admin}
                active={item.active}
                onDelete={() => removeUser(item._id)}
                loadUsers={() => loadUsers()}
                setLoading={setLoading}
                //ref={childRef}
              />
            )) :
            <div className="col-12 text-center">
              <span className="labelFilter">Nenhum usuário encontrado!</span>
            </div>}
          </ul>
      </div>
    </div>
  )
 
}