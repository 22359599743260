//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import 'react-toastify/dist/ReactToastify.css';
//import { Link } from 'react-router-dom';
import { FaYoutube, FaCalendarDay, FaMusic, FaUserFriends, FaReceipt, FaHome, FaSignOutAlt, FaRegPaperPlane, FaTicketAlt, FaBullhorn} from "react-icons/fa";
import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import DashboardHome from './DashboardHome';
import DashboardOrders from './DashboardOrders';
import DashboardCoupons from './DashboardCoupons';
import DashboardNotifications from './DashboardNotifications';
import DashboardUsers from './DashboardUsers';
import DashboardPromotions from './DashboardPromotions';
import Loading from '../../components/Loading';
import './styles.css';

Modal.setAppElement(document.getElementById('root'));

export default function Dashboard({ history }) {
  const [loading, setLoading] = useState(true);
  const [userLogado, setUserLogado] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [listMenuActive, setListMenuActive] = useState(0);
  const [modalYoutube, setModalYoutube] = useState(false);
  const [linkYoutube, setLinkYoutube] = useState('');

  useEffect(() => {
    const user_id = sessionStorage.getItem('user');
    if(user_id) {
      validaLogin(user_id);
    } else {
      history.push('/painel-admin/login');
    }
  }, []);

  useEffect(() => {
    optionMenu(listMenuActive);
  }, [listMenuActive]);

  async function validaLogin(user_id) {
    if(user_id){
      const response = await Api.getUser(user_id);
      console.log(response)
      if(response && response.hasOwnProperty('_id')){
        console.log("Usuário logado!")
        setUserLogado(true);
        //setListMenuActive(5);
        setLoading(false);
      } else {
        history.push('/painel-admin/login');
      }
    }
  }

  function logout(){
    sessionStorage.removeItem('user');
    history.push('/');
  }

  function optionMenu(menu){
    if(menu === 1) {
      setListMenuActive(1);
    } else if(menu === 2) {
      setListMenuActive(2);
    } else if(menu === 3) {
      setListMenuActive(3);
    } else if(menu === 5) {
      setListMenuActive(5);
    } else if(menu === 6) {
      //setListMenuActive(6);
      //setModalYoutube(!modalYoutube);
    } else if(menu === 7) {
      setListMenuActive(7);
    }else if(menu === 4) {
      logout();
    } else {
      setListMenuActive(0);
    }
    setToggleMenu(false);
  }
  
  if(userLogado){
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 barraSuperior">
              <div className="row v-center">
                <div className="col-4 text-left logo">
                  <img id="logo-barra" src={logo} width="100%" onClick={() => setListMenuActive(0)} style={{cursor: "pointer"}}/>
                </div>
                <div className="col-4 text-center">
                  <span id="titleMenu"> 
                    {listMenuActive === 0 ? 'Home' : ''}
                    {listMenuActive === 1 ? 'Pedidos' : ''}
                    {listMenuActive === 2 ? 'Banners' : ''}
                    {listMenuActive === 5 ? 'Usuários' : ''}
                    {listMenuActive === 6 ? 'Transmissão' : ''}
                    {listMenuActive === 7 ? 'Cupons' : ''}
                    {listMenuActive === 3 ? 'Notificações' : ''}
                  </span>
                </div>
                <div className="col-4 text-right imgMenu">
                  <span className="fa fa-bars fa-2x"id="btnMenu" onClick={() => toggleMenu ? setToggleMenu(false) : setToggleMenu(true)} alt="Menu"></span>
                </div>
              </div>
          </div>
        </div>
        <ul id="menuUser" className={`${toggleMenu ? 'active' : ''}`}>
          <li onClick={() => setListMenuActive(0)} className={`${listMenuActive === 0 ? 'active' : ''}`}><FaHome size="2em" className="itemHomeIcon"/> Home</li>
          <li onClick={() => setListMenuActive(1)} className={`${listMenuActive === 1 ? 'active' : ''}`}><FaReceipt size="2em" className="itemHomeIcon"/> Pedidos</li>
          <li onClick={() => setListMenuActive(2)} className={`${listMenuActive === 2 ? 'active' : ''}`}><FaBullhorn size="2em" className="itemHomeIcon"/> Banners </li>
          <li onClick={() => setListMenuActive(7)} className={`${listMenuActive === 7 ? 'active' : ''}`}><FaTicketAlt size="2em" className="itemHomeIcon"/> Cupons </li>
          {/*<li onClick={() => {setModalYoutube(!modalYoutube); setToggleMenu(false)}} className={`${listMenuActive === 6 ? 'active' : ''}`}><FaYoutube size="2em" className="itemHomeIcon"/> Transmissão </li>*/}
          <li onClick={() => setListMenuActive(5)} className={`${listMenuActive === 5 ? 'active' : ''}`}><FaUserFriends size="2em" className="itemHomeIcon"/> Usuários </li>
          <li onClick={() => setListMenuActive(3)} className={`${listMenuActive === 3 ? 'active' : ''}`}><FaRegPaperPlane size="2em" className="itemHomeIcon"/> Notificações </li>
          <li onClick={() => setListMenuActive(4)} className={`${listMenuActive === 4 ? 'active' : ''}`}><FaSignOutAlt size="2em" className="itemHomeIcon"/> Sair</li>
        </ul>
        <Loading visible={loading}/>
        <div className="row v-center">
          {listMenuActive === 0 ? <DashboardHome callbackParent={(menu) => optionMenu(menu)} loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 1 ? <DashboardOrders loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 2 ? <DashboardPromotions loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 5 ? <DashboardUsers loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 7 ? <DashboardCoupons loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
          {listMenuActive === 3 ? <DashboardNotifications loading={loading} setLoading={(value) => setLoading(value)} /> : ''}
        </div>
        <Modal
          isOpen={modalYoutube}
          //onAfterOpen={afterOpenModal}
          onRequestClose={() => {setModalYoutube(!modalYoutube)}}
          style={
            { overlay: {
              background: 'rgba(0,0,0,0.5)'
            }, content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '95%',
              maxWidth: '550px',
              border: 0,
              padding: '15px',
              borderRadius: '15px',
              boxShadow: '0px 0px 5px -2px #bbbbbb'
            } }
          }
          contentLabel="Example Modal"
        >
          <button className="btn-closeModal" onClick={() => {setModalYoutube(!modalYoutube)}}>✘</button>
          <div className="form-group col-12 text-center">
            <br />
            <h4 style={{marginBottom: '1.5rem'}}><b>Transmissão ao Vivo</b></h4>
          </div>
          <div className="form-group col-12">
            <label className="labelInput">Id da Transmissão do Youtube:</label>
            <label className="labelInputInner">https://www.youtube.com/embed/ </label>
            <input 
              type="text" 
              className="form-control" 
              name="linkYoutube" 
              id="linkYoutube"
              style={{paddingLeft: '262px'}} 
              placeholder="Id do vídeo de transmissão do Youtube"
              value={linkYoutube}
              onChange={event => setLinkYoutube(event.target.value)}/>
          </div>
          <a href={"https://www.youtube.com/embed/"+linkYoutube} className="linkListItem" target="_Blank">Testar Link</a>
          <br />
          <div className="form-group col-12 text-center">
            <button className="btn btn-default" onClick={() => {}}> Salvar </button>
          </div>
        </Modal>
        <ToastContainer />
      </div>
    );
  } else {
    /*return (<div>Falha ao validar o usuário logado, você será redirecionado em...</div>)*/
    return (<div></div>)
  }
 
}