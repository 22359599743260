import React from 'react';

const formatMoney = (amount) => {
  var decimalCount = 2, decimal = ",", thousands = ".";
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
};

const checkMonth = (month) => {
  var arrMonth=new Array();
  arrMonth[1]='Jan';
  arrMonth[2]='Fev';
  arrMonth[3]='Mar';
  arrMonth[4]='Abr';
  arrMonth[5]='Mai';
  arrMonth[6]='Jun';
  arrMonth[7]='Jul';
  arrMonth[8]='Ago';
  arrMonth[9]='Set';
  arrMonth[10]='Out';
  arrMonth[11]='Nov';
  arrMonth[12]='Dez';
  return arrMonth[parseInt(month)];
}

const getSizeLabel = (size) => {
  var label = "";
  switch (size) {
    case "9x12.7":
      label = "9x12"
      break;
    case "12.7x9":
      label = "12x9"
      break;
    case "15.2x10":
      label = "15x10"
      break;
    case "10x15.2":
      label = "10x15"
      break;
    case "18x12.7":
      label = "18x13"
      break;
    case "12.7x18":
      label = "13x18"
      break;
    case "21x15.2":
      label = "21x15"
      break;
    case "15.2x21":
      label = "15x21"
      break;
    case "25x20.3":
      label = "25x20"
      break;
    case "20.3x25":
      label = "20x25"
      break;
    case "30x20.3":
      label = "30x20"
      break;
    case "20.3x30":
      label = "20x30"
      break;
    default:
      label = ""
      break;
  }
  return label;
}

const Functions = {
  formatMoney,
  checkMonth,
  getSizeLabel
    
}
export default Functions;
  