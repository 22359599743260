import axios from 'axios';

var URL_FILES = 'https://storage.googleapis.com/schlosser-files';
var URL_FILES_ORDERS = 'https://storage.googleapis.com/schlosser-orders';
var BASE_API = 'https://server-zoxxycfldq-rj.a.run.app/api';
var BASE_URL = 'https://server-zoxxycfldq-rj.a.run.app';
//var BASE_URL = 'http://192.168.1.110:3000';
//var BASE_API = 'http://192.168.1.110:3000/api';

const api = axios.create({
  baseURL: BASE_API
});
const getUser = async (user_id) => {
  try {
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data[0];
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const getUsers = async () => {
  try {
    const response = await api.get(`/get-users/`);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}
const loadUserLogged = async () => {
  try {
    const user_id = await AsyncStorage.getItem('user') || "";
    const response = await api.get(`/get-users/${user_id}`);
    if(response){
      return response.data[0];
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const sendNotification = async (title, body, filter, token) => {
  const response = await api.post('/send-notification', {
          title,
          body,
          filter,
          token
      }
  );
  return response;
}

const loadDataAtual = async () => {
  const response = await api.get('/data-hora-atual');
  return response.data.dataHoraAtual;
}

const get = async (route) => {
  try {
    const response = await api.get(route);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const post = async (route, data) => {
  try {
    const response = await api.post(route, data);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const saveItem = async (route, data) => {
  try {
    const response = await api.post(route, data);
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

const deleteItem = async (route, id) => {
  try {
    const response = await api.post(route, { id });
    if(response){
      return response.data;
    } else {
      return {message: "Lamentamos, houve uma falha ao processar a requisição!", success: false};
    }
  } catch (error) {
    console.log(error);
    return {message: error.errmsg, success: false};
  }
}

export default {
  api,
  BASE_API,
  BASE_URL,
  URL_FILES,
  URL_FILES_ORDERS,
  loadUserLogged,
  getUsers,
  getUser,
  sendNotification,
  loadDataAtual,
  get,
  post,
  saveItem,
  deleteItem
};