import React from 'react';
import Routes from './routes';
import './App.css';

function App() {
  return (
    <Routes />  
  );
}

export default App;
