//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState, useContext} from 'react';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import ListItemOrder from '../../components/ListItemOrder'
//import UserContext from '../../contexts/UserContext';
import './styles.css';
import '../../App.css';

export default function DashboardOrders({setLoading}) {
  const [user, setUser] = useState([]);
  const [orders, setOrders] = useState([]);
  //const [startDate, setStartDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)), new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000))]);
  const [startDate, endDate] = dateRange;

  // Executa assim q a página é aberta; 
  useEffect(() => {
    var interval = 0
    if(endDate){
      //console.log(endDate.toISOString())
      loadOrders();
      interval = setInterval(() => {
        loadOrders();
      }, 300000); // 5 min
    }
    return () => clearInterval(interval);
  }, [dateRange]);

  async function loadOrders() {
    console.log("Buscando pedidos --> " + new Date());
    try {
      setLoading(true);
      const response = await Api.get('/get-orders/'+startDate.toISOString().substr(0,10)+"/"+endDate.toISOString().substr(0,10));
      console.log(response);
      setOrders(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  /*async function addOrder(){
    const orderCopy = Array.from(orders);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    orderCopy.unshift({
      _id: orderCopy.length +1,
      order: "",
      client: [],
      items: [],
      shippingMethod: "",
      appliedCashbacks: [],
      values: [],
      status: [],
      shop: user[0].user.adminShop ? user[0].user.shop : {"_id": "", "name": "", "state": ""}, // {"_id": user[0].user.shop._id, "name": user[0].user.shop.name, "state": user[0].user.shop.state}
      dateCreated: new Date().toISOString(),
      active: true,
      //date: new Date().toISOString().substr(0,10),
    });
    await setOrders(orderCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(orderCopy.length)
  }

  async function removeOrder(id){
    setLoading(true);
    const orderCopy = Array.from(orders);
    var index = orderCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.deleteItem('/delete-order', id);
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          orderCopy.splice(index, 1);
          setOrders(orderCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      orderCopy.splice(index, 1);
      setOrders(orderCopy);
    }
    setLoading(false);
  }*/

  //console.log(orders);
  //const childRef = useRef();
  return (
    <div id="orderList" className="bodyDashboard">
      <div className="v-center">
        {/*<div className="col-2">
          <span className="titleDate">Pedidos</span>
          <i className="fa fa-sync-alt"></i>
        </div>*/}
        <div className="col-12">
        <div className="row">
          <span className={`fas fa-calendar-week fa-lg iconDate`}></span>
          {/*<button type="button" onClick={addOrder} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>*/}
          <DatePicker 
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            className="titleDate"
            dateFormat={'dd/MM/yyyy'}
            //customInput={({ value, onClick }, ref) => <button className="titleDate"onClick={onClick} ref={ref}>{value}</button>}
            withPortal 
          />
          <span onClick={()=> loadOrders()} className={`fas fa-sync-alt fa-lg iconRefresh`}></span>
        </div>
        </div>
      </div>
        
      <div className="col-12">
        <ul className="dashboardList">
          {orders.length > 0 ?
          orders.map(item => (
            <ListItemOrder
              key={item._id}
              id={item._id}
              order={item.order}
              client={item.user}
              items={item.items}
              payment={item?.payment ? item.payment : {}}
              addressDelivery={item?.addressDelivery ? item.addressDelivery : {}}
              shippingMethod={item.shippingMethod}
              appliedCashbacks={item?.appliedCashbacks ? item.appliedCashbacks : []}
              appliedCoupon={item?.appliedCoupon ? item.appliedCoupon : {}}
              dateCreated={item.dateCreated}
              values={item.values}
              status={item.status}
              rating={item?.rating ? item.rating : {}}
              active={item.active}
              //onSelectShop={(state) => loadShops(state)}
              //onDelete={() => removeOrder(item._id)}
              loadItem={() => loadOrders()}
              setLoading={setLoading}
              //ref={childRef}
            />
          )) :
          <div className="col-12 text-center">
            <br />
            <br />
            <br />
            <span className="labelFilter">Nenhum há pedidos para exibir!</span>
          </div>}
        </ul>
      </div>
    </div>
  )
 
}