//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemPromotions from '../../components/ListItemPromotions'
import './styles.css';
import '../../App.css';

export default function DashboardPromotions({setLoading}) {
  const [promotions, setPromotions] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadPromotions();
  }, []);

  async function loadPromotions() {
    try {
      const response = await Api.get(`/get-promotions`);
      setPromotions(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addPromotion(){
    const promotionCopy = Array.from(promotions);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    promotionCopy.unshift({
      _id: promotionCopy.length +1,
      name: "",
      //thumbnail: "",
      city: "",
      state: "",
      link: "", 
      order: 0,
      active: true
    });
    await setPromotions(promotionCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(promotionCopy.length)
  }

  async function removePromotion(id){
    setLoading(true);
    const promotionCopy = Array.from(promotions);
    var index = promotionCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-promotion', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          promotionCopy.splice(index, 1);
          setPromotions(promotionCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      promotionCopy.splice(index, 1);
      setPromotions(promotionCopy);
    }
    setLoading(false);
  }

  return (
    <div id="promotionList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Banners</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addPromotion} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
        <div className="col-12">
          <ul className="dashboardList">
            {
            promotions.length > 0 ?
            promotions.map(item => (
              <ListItemPromotions
                key={item._id}
                id={item._id}
                name={item.name}
                thumbnail={item.thumbnail} 
                link={item.link} 
                order={item.order} 
                active={item.active} 
                onDelete={() => removePromotion(item._id)}
                loadItem={() => loadPromotions()}
                setLoading={setLoading}
              />
            )) :
            <div className="col-12 text-center">
              <br />
              <br />
              <br />
              <span className="labelFilter">Não há banners cadastrados!</span>
            </div>
          }
          </ul>
      </div>
    </div>
  )
 
}