import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import Api from '../services/Api'
import camera from '../assets/icons/camera.svg';

const ListItemUser = ({ id, name, email, telefone, cpf, dataNascimento, dateCreated, catequeseList, admin, token, active, onDelete, loadUsers, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [StateUserName, setStateUserName] = useState(name);
  const [StateUserEmail, setStateUserEmail] = useState(email);
  const [stateTelefone, setStateTelefone] = useState(telefone);
  const [stateDataNascimento, setStateDataNascimento] = useState(dataNascimento);
  const [stateDateCreated, setStateDateCreated] = useState(dateCreated);
  const [stateActive, setStateActive] = useState(active);
  const [stateAdmin, setStateAdmin] = useState(admin);

  
  async function handleSubmit(e){
    e.preventDefault();
    setLoading(true);
    try {
      const response = await Api.post('/update-user', {
        id: id,
        active: stateActive,
        admin: stateAdmin,
        updateStatusUser: true
      });
      if(response.ok === 1){
        toast.success("Operação realizada com sucesso!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setToggleItem([]);
        const reloadUsers = loadUsers;
        reloadUsers();
        /*if(!Number.isInteger(id) && token && token !== ""){
          if(!active && stateActive){
            console.log("Envia notificação");
            Api.sendNotification("Cadastro Aprovado!", 'Seu cadastro foi aprovado, agora você pode conferir os conteúdos da catequese! Acesse o app e confira!', "*", token);
          }
        }*/
        setLoading(false);
      } else {
        toast.error("Falha ao realizar a operação!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
      <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
        <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
          <div className="col-10">
            <span>{StateUserName + ' - ' + StateUserEmail} </span>
          </div>
          <div className="col-2 text-right">
            <span className={`iconItem ${!active ? 'fas fa-ban fa-lg ' : ''}`} style={{color: 'red'}}></span>
            <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
          </div>
        </div>
        {toggleItem[0] === id ?
          <div className="row">
            <div className="col-12">
              <hr/>
              <form onSubmit={handleSubmit}>
                <div className="row v-center">
                  <div className="col-12">
                    <label className="switch itemDashboardListSwitch">
                      <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                      <div className="slider"></div>
                    </label>
                    <div className="row">
                      {/*<div className="form-group col-12">
                        <label className="labelInput">Nome:</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          name="name"
                          placeholder="Nome do Usuário"
                          value={StateUserName}
                          onChange={event => setStateUserName(event.target.value)}
                        />
                      </div>  
                      <div className="form-group col-12">
                        <label className="labelInput">Email:</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          name="name"
                          placeholder="Nome da Usuário"
                          value={StateUserEmail}
                          onChange={event => setStateUserEmail(event.target.value)}
                        />
                      </div>*/}
                      <div className="form-group col-12">
                        <label className="">Informações:</label>
                        <div className="row" style={{marginLeft:5}}>
                          <div className="form-group col-12 col-md-6">
                            <div>Nome: <label>{StateUserName}</label></div>
                            <div>Telefone: <label>{
                              stateTelefone && stateTelefone.length > 0 ?
                                stateTelefone.substr(5).charAt(0) === "9" ?
                                  <a href={"https://wa.me/55"+stateTelefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")} target="_Blank">{stateTelefone} <span className="fab fa-whatsapp fa-lg" style={{marginLeft: 3, color: "green"}}></span></a>
                                : stateTelefone
                              : ""
                            }</label></div>
                            <div>CPF: <label>{cpf}</label></div>
                          </div>  
                          <div className="form-group col-12 col-md-6">
                            <div>Email: <label>{StateUserEmail}</label></div>
                            <div>Data Nascimento: <label>{stateDataNascimento ? stateDataNascimento.substr(0,10).split("-").reverse().join('/') : ' __/__/__ '}</label></div>
                            <div>Status do Cadastro: <label>{active ? 'Ativo' : 'Aguardando Ativação'}</label></div>
                            {/*<div>Data Cadastro: <label>{new Date(stateDateCreated).toLocaleDateString()}</label></div>*/}
                          </div>  
                        </div>  
                      </div> 
                      <div className="form-group col-12">
                        <label className="labelInput">Usuário Admin:</label>
                        <select className="form-control" value={stateAdmin} onChange={event => setStateAdmin(event.target.value)}>
                          <option value={false}>Não</option>
                          <option value={true}>Sim</option>
                        </select>
                      </div> 
                    </div>     
                  </div>     
                      
                  <div className="col-12 text-right">
                    <button type="button" className="btn btn-delete" onClick={onDelete}>Excluir</button>
                    <span> </span>
                    <button type="submit" className="btn btn-default">Salvar</button>
                  </div>
                </div>
              </form>
                
            </div>
          </div>
          : '' }
      </li>
  );
};

export default ListItemUser;
