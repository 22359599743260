//useEffect = Método chamado ao carragar a página;
import React, { useEffect, useState} from 'react';
import { toast } from 'react-toastify';
//import { Link } from 'react-router-dom';
//import logo from '../../assets/logo.png';
import Api from '../../services/Api'
import Functions from '../../services/Functions'
import ListItemCoupons from '../../components/ListItemCoupons'
import './styles.css';
import '../../App.css';

export default function DashboardCoupons({setLoading}) {
  const [coupons, setCoupons] = useState([]);

 // Executa assim q a página é aberta;
  useEffect(() => {
    setLoading(true);
    loadCoupons();
  }, []);

  async function loadCoupons() {
    try {
      const response = await Api.get("/get-coupons");
      setCoupons(response);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }
  
  async function addCoupon(){
    const couponCopy = Array.from(coupons);
    // unshift() - Add no inicio do vetor;  push() - add no fim do vetor; 
    couponCopy.unshift({
      _id: couponCopy.length +1,
      coupon: "", 
      benefit: "Desconto em R$", 
      benefitValue: "", 
      firstOrder: false, 
      sumCashback: true, 
      units: 10000, 
      userMaxQuantity: 1, 
      orderMinValue: 0, 
      orderMaxValue: 1000, 
      discountMax: 100, 
      startDate: new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString(), 
      expirationDate: new Date(new Date().setDate(new Date().getDate() + 30)).toISOString(), 
      active: true
    });
    await setCoupons(couponCopy);
    //Gambiarra
    document.getElementsByClassName("headList")[0].click();
    //childRef.current.getAlert(couponCopy.length)
  }

  async function removeCoupon(id){
    setLoading(true);
    const couponCopy = Array.from(coupons);
    var index = couponCopy.findIndex(function(item, i){
      return item._id === id
    });
    if(id.toString().match(/^[0-9a-fA-F]{24}$/)) {
      if(window.confirm('Deseja realmente deletar este registro?')){
        const response = await Api.post('/delete-coupon', {id: id});
        console.log(response);
        if(Number(response.ok) === 1){
          toast.success("Operação realizada com sucesso!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          couponCopy.splice(index, 1);
          setCoupons(couponCopy);
        } else {
          toast.error("Falha ao realizar a operação!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    } else {
      couponCopy.splice(index, 1);
      setCoupons(couponCopy);
    }
    setLoading(false);
  }

  return (
    <div id="couponList" className="bodyDashboard">
      <div className="v-center">
        <div className="col-4">
          <span className="title">Cupons</span>
        </div>
        <div className="col-4 text-center">
        </div>
        <div className="col-4 text-right">
          <button type="button" onClick={addCoupon} className="btnNew"><i className="fa fa-plus fa-lg"></i></button>
        </div>
      </div>
      <div className="col-12">
        <ul className="dashboardList">
          {
          coupons.length > 0 ?
          coupons.map(item => (
            <ListItemCoupons
              key={item._id}
              id={item._id}
              coupon={item.coupon}
              benefit={item.benefit} 
              benefitValue={item.benefitValue} 
              firstOrder={item.firstOrder} 
              sumCashback={item.sumCashback} 
              units={item.units} 
              userMaxQuantity={item.userMaxQuantity} 
              orderMinValue={item.orderMinValue}
              orderMaxValue={item.orderMaxValue}
              discountMax={item.discountMax}
              startDate={item.startDate}
              expirationDate={item.expirationDate}
              active={item.active} 
              onDelete={() => removeCoupon(item._id)}
              loadItem={() => loadCoupons()}
              setLoading={setLoading}
              //ref={childRef}
            />
          )) :
          <div className="col-12 text-center">
            <span className="labelFilter">Não há cupons cadastrados!</span>
          </div>
        }
        </ul>
      </div>
    </div>
  )
 
}