import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from 'file-saver';
import Api from '../services/Api'
import Functions, { sendNotification } from '../services/Functions'
import { FaImages } from "react-icons/fa";

const ListItemOrder = ({ id, order, client, items, shippingMethod, addressDelivery, appliedCoupon, values, payment, dateCreated, status, rating, collectInTable, active, onDelete, loadItem, setLoading }) => {
  const [toggleItem, setToggleItem] = useState([]);
  const [stateActive, setStateActive] = useState(active);
  //const [statusOrder, setStatusOrder] = useState(status);
  const [statusSelect, setStatusSelect] = useState(0);
  const [statusPayment, setStatusPayment] = useState(payment);
  const elementRef = useRef(null);
  const [width, setWidth] = useState(0);
  
  useEffect(() => {
    if(status && status.length > 0){ 
      setStatusSelect(status[status.length-1].code);
    }
    //if (!elementRef.current) return;
    //setWidth(elementRef.current.getBoundingClientRect().width);
    //setWidth(elementRef.current.offsetWidth);
  }, []);

  /*async function download(url, filename) {
    const blob = await axios.get("https://storage.googleapis.com/storage/v1/b/schlosser-orders/o/P60KTEV7LQQ/IMG_20200126_060922_626.jpg?alt=media", {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ya29.a0ARrdaM-UZv0LNOLOrGVWh5NCPAFAkXDlTh3vCgxSia5CIfgZCzSMbFcN6lpkqma_g3dpqdY9_GEbnaOLXaeLi3Eg04j8rh0eAWCYRd89MCLs7lqs_LnwsDZaAbEaEwHoCbCJxnd_ZbXnTutxejKQG-B0fP10'
      },
      responseType: 'blob',
    });
    const a = document.createElement('a');
    const href = window.URL.createObjectURL(blob.data);
    a.href = href;
    a.download = filename;
    a.click();
  }*/

  async function downloadImages(order, items) {
    setLoading(true);
    var image, imageBlob, imageFile, fileName, imageUrl, count = 0;
    var zip = new JSZip();
    //var folder = zip.folder('images');
    var zipFilename = order+"_"+Functions.getSizeLabel(items[0].size);
    items.forEach(function (item, i) {
      var filename = item.filename.split(".")[0] + "_COPIAS(" + item.quantity + "x)."+item.filename.split(".")[item.filename.split(".").length -1];
      //filename = filename.replace(/[\/\*\|\:\<\>\?\"\\]/gi, '').replace("httpsi.imgur.com","");
      imageUrl = `https://storage.googleapis.com/download/storage/v1/b/schlosser-orders/o/${order}%2F${encodeURI(filename)}?alt=media`;
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(imageUrl, function (err, data) {
        count++;
        if(err) {
          if(count == items.length) {
            toast.error("Não foi possível realizar o download dos arquivos!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
          throw err;
        }
        zip.file(filename, data, { binary: true });
        if(count == items.length) {
          zip.generateAsync({ type: 'blob' }).then(function (content) {
            saveAs(content, zipFilename);
            setLoading(false);
          });
        }
      });
    });
    /*await items.forEach(async(item, index) => {
      fileName = await item.filename.split(".")[0] + "_COPIAS(" + item.quantity + "x)."+item.filename.split(".")[item.filename.split(".").length -1];
      imageUrl = `https://storage.googleapis.com/download/storage/v1/b/schlosser-orders/o/${order}%2F${fileName}?alt=media`;
      console.log(imageUrl);
      console.log(fileName);
      // Fetch the image and parse the response stream as a blob
      //const imageBlob = fetch(imageUrl).then(response => response.blob());
      image = await fetch(imageUrl);
      console.log(image)
      if(image.ok || image.status === 200){
        imageBlob = await image.blob();
        // create a new file from the blob object
        imageFile = new File([imageBlob], fileName);
        console.log(imageFile);
        // Add the image to the folder 
        folder.file(fileName, imageFile);
        //console.log(folder);
        if(index+1 === items.length){
          // Generate a zip file asynchronously and trigger the download 
          folder.generateAsync({ type: "blob" }).then(content => saveAs(content, zipName));
        } 
      } else {
        if(index+1 === items.length){
          toast.error("Não foi possível realizar o download dos arquivos!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    });*/
    /*const a = document.createElement('a');
    const href = window.URL.createObjectURL(imageFile);
    a.href = href;
    a.download = items[0].filename;
    a.click();*/
  }

  async function handleSubmit(e){
    e.preventDefault();
    //if(statusSelect && parseInt(statusSelect) !== parseInt(status[status.length-1].code)){
      setLoading(true);
      try {
          var route = "/update-order";
          var data = {
            id: id,
            order: order,
            codeStatus: parseInt(statusSelect),
            payment: statusPayment,
            appliedCoupon: appliedCoupon,
            //active: stateActive
          };
          const response = await Api.post(route, data);
          if((response?.ok  && response.ok === 1) || response.hasOwnProperty("user")){
            toast.success("Operação realizada com sucesso!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setToggleItem([]);
            const reloadItem = loadItem;
            reloadItem();
            setLoading(false);
          } else {
            toast.error("Falha ao realizar a operação!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
        } catch (error) {
          console.log(error);
        }
    /*} else {
      toast.error("(*) Preencha todos os campos obrigatórios!", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }*/
  }
  
  return (
    <li className={toggleItem[0] === id ? "itemDashboardList itemDashboardListActive" : "itemDashboardList"}>
      <div className="row headList" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>
        <div className="col-8">
          <span>Pedido: </span> <span style={{textTransform: "uppercase", fontWeight: "bold", fontFamily: "Helvetica"}}>{'#' + order} </span>
        </div>
        <div className="col-4 text-right">
          {/* PAYMENT STATUS */}
          {payment.codeStatus === 2 ?
            <span className={`iconItem fas fa-dollar-sign`} style={{color: 'green'}}></span>
          : payment.codeStatus === 3 ?
            <span className={`iconItem fas fa-dollar-sign`} style={{color: 'red'}}></span>
          : 
            <span className={`iconItem fas fa-dollar-sign`} style={{color: 'gray'}}></span> 
          }
          {/* STATUS ORDER */}
          {status[status.length-1].code === 0 ?
            <span className={`iconItem fas fa-exclamation-triangle`} style={{color: 'rgb(255 189 3)'}}></span>
          : <></> }
          {status[status.length-1].code === 1 ?
            <span className={`iconItem fas fa-check`} style={{color: '#075faa'}}></span>
          : <></> }
          {status[status.length-1].code === 2 || status[status.length-1].code === 6 ?
            <span className={`iconItem fas fa-ban`} style={{color: 'red'}}></span>
          : <></> }
          {status[status.length-1].code === 3 ?
            <span className={`iconItem fas fa-cogs`} style={{color: '#075faa'}}></span>
          : <></> }
          {status[status.length-1].code === 4 ?
            <span className={`iconItem fas fa-shipping-fast`} style={{color: '#075faa'}}></span>
          : <></> }
          {status[status.length-1].code === 7 ?
            <span className={`iconItem fas fa-thumbs-up`} style={{color: '#075faa'}}></span>
          : <></> }
          {status[status.length-1].code === 5 ?
            <span className={`iconItem fas fa-check-circle`} style={{color: 'green'}}></span>
          : <></> }
          <span className={`iconItem ${toggleItem[0] === id ? 'fas fa-angle-up fa-lg ' : 'fas fa-angle-down fa-lg '}`}></span>
        </div>
      </div>
      {toggleItem[0] === id ?
        <div className="row">
          <div className="col-12">
            <hr/>
            <form onSubmit={handleSubmit}>
              <div className="row v-center">
                <div className="col-12">
                  {/*<label className="switch itemDashboardListSwitch">
                    <input type="checkbox" value={stateActive} checked={stateActive} onChange={() => setStateActive(!stateActive)} />
                    <div className="slider"></div>
                  </label>*/}
                  <div className="row">
                    <div className="form-group col-12">
                      <h5>Detalhes do Cliente:</h5>
                      <div className="row" style={{marginLeft:-10}}>
                        <div className="col-12 col-md-6">
                          <div>Cliente: <b>{client.name}</b></div>
                          <div>Email: <b>{client.email}</b></div>
                        </div>  
                        <div className="col-12 col-md-6">
                          <div>CPF: <b>{client.cpf}</b></div>
                          {/*<div>Telefone: <label>{
                            stateTelefone && stateTelefone.length > 0 ?
                              stateTelefone.substr(5).charAt(0) === "9" ?
                                <a href={"https://wa.me/55"+stateTelefone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "")} target="_Blank">{stateTelefone} <span className="fab fa-whatsapp fa-lg" style={{marginLeft: 3, color: "green"}}></span></a>
                              : stateTelefone
                            : ""}
                          </label></div>*/}
                        </div>  
                      </div>  
                    </div>
                    <div className="form-group col-12">
                      <h5>Detalhes do Pedido:</h5>
                      <div className="row" style={{marginLeft:-10, paddingBottom:15}}>  
                        <div className="col-12 col-md-6">
                          <div>Pedido: <b>{order.toUpperCase()}</b></div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div>Realizado em: <b>{dateCreated.substr(0,10).split("-").reverse().join("/")} às {dateCreated.substr(11,5)}h</b></div>
                        </div>
                      </div>
                      <label className="labelInput">Status do Pedido:</label>
                      <select className="form-control" value={statusSelect} onChange={event => setStatusSelect(event.target.value)}>
                        <option value={0}>Pedido Realizado</option>
                        <option value={1}>Pedido Aprovado</option>
                        <option value={2}>Pedido Recusado</option>
                        <option value={3}>Pedido em Produção</option>
                        <option value={4}>Pedido Enviado</option>
                        <option value={5}>Pedido Entregue</option>
                        <option value={7}>Pedido Pronto para Retirada</option>
                        <option value={6}>Pedido Cancelado</option>
                      </select>
                      <div className="form-group col-12"></div>
                      <div className="row listHorizontal">
                        <div className="col-12">
                          {items.map((item, index) => (
                            <div key={index} className="row v-center itemListHorizontal" onClick={() => {}}>
                              <div ref={elementRef} className="col-12 col-md-2 text-center" style={{paddingBottom: 8, paddingTop: 8}}>
                                {/*<div className="imgItemListHorizontal" style={item.border === "Sem borda" ? { backgroundImage: `url(${Api.URL_FILES_ORDERS}/${order}/${item.filename})`, backgroundSize: 'cover', height: ((item.size.split("x")[1]*280) / item.size.split("x")[0])} : { backgroundImage: `url(${Api.URL_FILES_ORDERS}/${order}/${item.filename})`, height: ((item.size.split("x")[1]*280) / item.size.split("x")[0])}}></div>*/}
                                <FaImages size="4em" className="itemHomeIcon"/>
                              </div>
                              <div className="col-12 col-md-7" style={{padding: 15}}>
                                <h6><b>{item.numberImages}x Imagens no tamanho {Functions.getSizeLabel(item.size)}</b></h6>
                                <div>Tamanho: <b>{Functions.getSizeLabel(item.size)} ({item.size})</b></div>
                                <div>Quantidade de Imagens: <b>{item.numberImages}</b></div>
                                <div>Quantidade de Cópias: <b>{item.numberCopies}</b></div>
                                <div>Valor Unitário: <b>R$ {Functions.formatMoney(item.totalItem/item.numberCopies)}</b></div>
                                <div>Total do Item: <b>R$ {Functions.formatMoney(item.totalItem)}</b></div>
                                {/*<div>Borda: <b>{item.border}</b></div>
                                <button type="button" className="btn btn-default" style={{marginTop: 5, fontSize: 14}} onClick={() => download("https://storage.googleapis.com/schlosser-orders/P60KTEV7LQQ/IMG_20200126_060922_626.jpg", "IMG_20200126_060922_626.jpg")}>
                                  Baixar Imagem
                                </button>*/}
                              </div>
                              <div className="col-12 col-md-3 text-center" style={{paddingBottom: 8, paddingTop: 8}}>
                                <button type="button" className="btn btn-default" style={{marginTop: 5, fontSize: 14}} onClick={() => downloadImages(order, item.items)}>
                                  Baixar Imagens
                                </button>
                                {/*<a href={`https://storage.googleapis.com/download/storage/v1/b/schlosser-orders/o/${order}%2F${item.filename}?alt=media`} download={item.filename} className="btn btn-default" style={{marginTop: 5, fontSize: 14}}>Baixar Imagens</a>*/}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="row" style={{marginLeft:5}}>
                        <div className="col-12">
                          {/*<ScrollMenu
                            arrowLeft={<div className="arrowLeft"><span className="fas fa-angle-left fa-lg"></span></div>}
                            arrowRight={<div className="arrowRight"><span className="fas fa-angle-right fa-lg"></span></div>}
                            //itemClass="row"
                            //dragging={false}
                            data={items.map((item, index) => (
                              <div key={index} className="itemListHorizontal" onClick={() => {}}>
                                <div className="imgItemListHorizontal" style={{ backgroundImage: `url(${Api.URL_FILES_ORDERS}/${order}/${item.filename})` }}></div>
                                <p className="text-center">{item.filename}</p>
                              </div>
                            ))}
                          />
                          <div className="row v-center" style={{marginBottom: 20}}>
                            <div className="text-center">
                              <FaImages size="4em" className="itemHomeIcon"/>
                            </div>
                            <div style={{marginLeft: 10}}>
                            <div>
                              <b style={{fontSize: 16}}>{items.length} imagens enviadas;</b>
                            </div>
                              {/*<button type="button" className="linkDownload" onClick={() => download("https://storage.googleapis.com/schlosser-orders/P60KTEV7LQQ/IMG_20200126_060922_626.jpg", "IMG_20200126_060922_626.jpg")}>
                                Baixar Imagens
                              </button>}
                              <a href={`data:image/jpeg,https://storage.googleapis.com/schlosser-orders/P60KTEV7LQQ/IMG_20200126_060922_626.jpg`} download="IMG_20200126_060922_626.jpg" className="linkDownload" target="_Blank">Baixar Imagens</a>
                            </div>
                          </div>*/}
                        </div>
                        <div className="col-12">
                          {appliedCoupon && appliedCoupon?.coupon && appliedCoupon.coupon !== "" ? 
                            <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <span style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Cupom Aplicado:</span>
                              <span style={{fontFamily: "", fontSize: 14, fontWeight: "inherit", color: "gray"}}>{appliedCoupon.coupon}</span>
                            </div>
                          : <></> }
                          {values.discount > 0 ?
                            <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <span style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Desconto: </span>
                              <span style={{fontFamily: "", fontSize: 14, fontWeight: "inherit", color: "gray"}}>R$ {Functions.formatMoney(values.discount)}</span>
                            </div> 
                          : <></>}
                          <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                            <span style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>SubTotal</span>
                            <span style={{fontFamily: "", fontSize: 14, fontWeight: "inherit", color: "gray"}}>R$ {Functions.formatMoney(values.totalItems)}</span>
                          </div>
                          {shippingMethod === "Delivery" ?
                            <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                              <span style={{textTransform: "uppercase", fontSize: 11, fontWeight: "bold", color: "gray"}}>Frete</span>
                              <span style={{fontFamily: "", fontSize: 14, fontWeight: "inherit", color: "gray"}}>{values.shipping > 0 ? 'R$ ' + Functions.formatMoney(values.shipping) : 'GRÁTIS'}</span>
                            </div> 
                          : <></>}
                          <div className="row" style={{width:"100%", justifyContent: "space-between"}}>
                            <span style={{fontFamily: "", fontWeight: "bold", fontSize: 18}}>TOTAL</span>
                            <span style={{fontFamily: "", fontWeight: "bold", fontSize: 18}}>R$ {Functions.formatMoney(values.total)}</span>
                          </div>
                        </div>  
                      </div>  
                    </div> 
                    <div className="form-group col-12">
                      <h5>Entrega:</h5>
                      <div className="row" style={{marginLeft:-10}}>
                        {shippingMethod === "Delivery" ?
                        <>
                          <div className="col-12 col-md-6">
                            <div>Forma de Entrega: <label>Delivery</label></div>
                          </div>  
                          <div className="col-12">
                            <div>Endereço de Entrega: <label>{addressDelivery.street}, {addressDelivery.number} {addressDelivery.complement}, {addressDelivery.district} - {addressDelivery.city}</label></div>
                          </div>
                        </> : <>
                          <div className="col-12">
                            <div>Forma de Entrega: <label>Retirada no Local</label></div>
                          </div>  
                        </>}  
                      </div>  
                    </div>
                    <div className="form-group col-12">
                      <h5>Pagamento:</h5>
                      <label className="labelInput">Status do Pagamento:</label>
                      <select className="form-control" value={statusPayment.codeStatus} onChange={event => { var index = event.nativeEvent.target.selectedIndex; setStatusPayment({codeStatus: parseInt(event.target.value), status: event.nativeEvent.target[index].text})}}>
                        <option value={0}>Pagamento Pendente</option>
                        <option value={1}>Pagamento em Análise</option>
                        <option value={2}>Pagamento Aprovado</option>
                        <option value={3}>Pagamento Devolvido</option>
                      </select>  
                    </div>
                    {rating?.stars && rating.stars > 0 ?
                      <div className="form-group col-12">
                        <h5>Feedback do Cliente:</h5>
                        <div className="row" style={{marginLeft:-10}}>
                          <div className="col-12">
                            <div>Estrelas: <label>{rating.stars}</label></div>
                          </div>  
                          <div className="col-12">
                            <div>Comentário: <label>{rating.comment}</label></div>
                          </div>  
                        </div>  
                      </div>
                    : <></>}
                    <div className="form-group col-12">
                    </div>
                  </div>     
                </div>     
                    
                <div className="col-12 text-right">
                  <button type="button" className="btn btn-delete" onClick={() => {toggleItem[0] !== id ? setToggleItem([id]) : setToggleItem([])}}>Fechar</button>
                  <span> </span>
                  <button type="submit" className="btn btn-default">Salvar</button>
                </div>
              </div>
            </form>
              
          </div>
        </div>
        : '' }
    </li>
  );
};

export default ListItemOrder;
